import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;

const NavBar = ({ className }) => {
    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
